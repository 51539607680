<template>
  <dashboard-list
    :items="items"
    :is-loading="isLoading"
    class-name="outstanding-table"
    emptyQuote="All Requests Already Responded"
    v-on:refreshItem="resetState"
    v-on:loadMore="loadMore"
    class="outstanding-table"
  >
    <template slot="body-table">
      <b-table-column label="No" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column field="id" label="ID Request" v-slot="props" sortable>
        <span class="is-capitalize">
          {{ props.row.id }}
        </span>
      </b-table-column>
      <b-table-column
        field="request_type"
        label="Request Type"
        v-slot="props"
        sortable
      >
        <span class="is-capitalize">
          {{ props.row.requestType }}
        </span>
      </b-table-column>
      <b-table-column field="created_by" label="Created By" v-slot="props">
        <span class="is-capitalize">
          {{ props.row.createdBy }}
        </span>
      </b-table-column>
    </template>
  </dashboard-list>
</template>
<script>
import { mapActions } from 'vuex'
import { formatDate, showToast } from '../../services/util'
import DashboardList from './DashboardList'
export default {
  components: {
    DashboardList,
  },
  data() {
    return {
      page: 0,
      lastPage: 0,
      perPage: 20,
      total: 0,
      sortField: 'id',
      defaultSortOrder: 'asc',
      sortOrder: 'asc',
      items: [],
      isLoading: false,
    }
  },
  methods: {
    ...mapActions({
      actionFetchOutstandingRequests:
        'outstandingRequest/fetchOutstandingRequest',
    }),
    formatDate(date, format) {
      return formatDate(date, format)
    },
    async resetState(data) {
      this.page = 0
      this.total = 0
      this.lastPage = 0
      this.items = []
      if (data) {
        this.sortField = data.sortField
        this.sortOrder = data.sortOrder
      }
      await this.fetchOutstandingRequests()
    },
    async fetchOutstandingRequests() {
      this.isLoading = true
      let params = {
        page: ++this.page,
        perPage: this.perPage,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      }
      try {
        let response = await this.actionFetchOutstandingRequests(params)
        this.items = [...this.items, ...response.data.data.data]
        this.total = response.data.data.total
        this.lastPage = response.data.data.lastPage
        this.$emit('outstandingMeta', response.data.data)
      } catch (err) {
        showToast(err.response.data.message)
        console.log(err)
      }
      this.isLoading = false
    },
    async loadMore() {
      if (this.page < this.lastPage) {
        // prevent loading too much data
        if (!this.isLoading) {
          await this.fetchOutstandingRequests()
        }
      }
    },
  },
  mounted() {
    this.fetchOutstandingRequests()
  },
}
</script>

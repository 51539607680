<template>
  <div class="late-dashboard">
    <r-page>
      <template #page-header>
        <h2 class="page-title">HR Dashboard</h2>
      </template>
      <template #page-content>
        <div class="columns is-multiline">
          <div class="column is-12 is-flex is-justify-content-flex-end">
            <b-datepicker
              v-model="selectedDates"
              :mobile-native="false"
              class="dropdown-left"
              style="z-index: 10"
            >
              <template v-slot:trigger>
                <b-button
                  icon-left="calendar-today"
                  type="is-primary"
                  class="attendance-calendar-btn"
                >
                  {{ formatDate(selectedDates, 'll') }}
                </b-button>
              </template>
            </b-datepicker>
          </div>
          <div class="column is-3-desktop-only is-6-tablet-only is-12-mobile">
            <dashboard-card
              title="Late Clock In"
              :total-data="lateTotal"
              unit="Person"
              icon="account-clock"
            ></dashboard-card>
          </div>

          <div class="column is-3-desktop-only is-6-tablet-only is-12-mobile">
            <dashboard-card
              title="Outstanding Request"
              :total-data="outstandingTotal"
              unit="Request"
              icon="file"
            ></dashboard-card>
          </div>

          <div class="column is-3-desktop-only is-6-tablet-only is-12-mobile">
            <dashboard-card></dashboard-card>
          </div>
          <div class="column is-3-desktop-only is-6-tablet-only is-12-mobile">
            <dashboard-card></dashboard-card>
          </div>
        </div>
        <div class="columns is-multiline">
          <div class="column is-6-desktop is-12-tablet is-12-mobile">
            <p class="is-size-4 has-text-weight-bold">
              Late Clockin
              <span class="has-text-grey-light is-size-6">(&gt; 11Am)</span>
            </p>
            <LateAttendanceList
              v-on:lateMeta="getLateCounter"
              :selectedDates="selectedDates"
            ></LateAttendanceList>
          </div>
          <div class="column is-6-desktop is-12-tablet is-12-mobile">
            <p class="is-size-4 has-text-weight-bold">Outstanding Request</p>
            <OutStandingRequestList
              v-on:outstandingMeta="getOutstandingCounter"
            ></OutStandingRequestList>
          </div>
        </div>
      </template>
    </r-page>
  </div>
</template>
<script>
import LateAttendanceList from '../components/Dashboard/DashboardLateAttendance'
import DashboardCard from '../components/DashboardCard'
import OutStandingRequestList from '../components/Dashboard/DashboardOutstandingRequest'
import { formatDate } from '../services/util'
export default {
  components: {
    DashboardCard,
    LateAttendanceList,
    OutStandingRequestList,
  },
  data() {
    return {
      selectedDates: new Date(),
      lateTotal: 0,
      outstandingTotal: 0,
    }
  },
  methods: {
    formatDate(date, format) {
      return formatDate(date, format)
    },
    getLateCounter(e) {
      this.lateTotal = e.total
    },
    getOutstandingCounter(e) {
      this.outstandingTotal = e.total
    },
  },
}
</script>

<template>
  <r-card>
    <div class="dashboard-list">
      <b-table
        height="250"
        :data="items"
        :per-page="perPage"
        :loading="isLoading"
        refs="myTable"
        @page-change="onPageChange"
        hoverable
        narrowed
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        :sticky-header="true"
        @sort="onSort"
      >
        <template slot="empty">
          <div
            class="dashboard-box is-flex is-align-items-center is-justify-content-center"
          >
            <div class="columns is-multiline has-text-centered">
              <div class="column is-12">
                <b-icon
                  type="is-primary"
                  icon="clock-check"
                  custom-size="mdi-48px"
                ></b-icon>
              </div>
              <div class="column is-12">
                <p class="has-text-grey-light is-size-5">
                  {{ emptyQuote }}
                </p>
              </div>
            </div>
          </div>
        </template>
        <slot name="body-table"></slot>
      </b-table>
    </div>
  </r-card>
</template>
<script>
export default {
  props: {
    emptyQuote: {
      type: String,
      default: 'Item is Empty',
    },
    items: {
      default: [],
    },
    isLoading: Boolean,
    className: String,
  },
  data() {
    return {
      page: 0,
      perPage: 20,
      sortField: 'id',
      defaultSortOrder: 'asc',
      sortOrder: 'asc',
    }
  },
  methods: {
    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.$emit('refreshItem', {
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      })
    },
    onPageChange(page) {
      this.page = page
      this.$emit('refreshItem', {
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      })
    },
    onScroll(divElement) {
      let that = this
      divElement.addEventListener('scroll', async function () {
        const { scrollTop, scrollHeight, clientHeight } = divElement
        if (scrollTop + clientHeight >= scrollHeight - 100) {
          that.$emit('loadMore')
        }
      })
    },
    async loadMore(className) {
      let element = '.' + className + ' .table-wrapper'
      let myTable = document.querySelector(element)
      myTable.addEventListener('scroll', () => this.onScroll(myTable))
    },
  },
  async beforeMount() {},
  async mounted() {
    await this.loadMore(this.className)
  },
}
</script>

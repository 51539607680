<template>
  <dashboard-list
    :items="items"
    :is-loading="isLoading"
    class-name="late-table"
    emptyQuote="All Employee Already Clock In On Time"
    v-on:refreshItem="resetState"
    v-on:loadMore="loadMore"
    class="late-table"
  >
    <template slot="body-table">
      <b-table-column field="full_name" label="Name" v-slot="props" sortable>
        <span class="is-capitalize">
          {{ props.row.fullName }}
        </span>
      </b-table-column>
      <b-table-column
        field="attendance_start_time"
        label="Clock In"
        v-slot="props"
        sortable
      >
        <span class="is-capitalize">
          {{ formatDate(props.row.attendanceStartTime, 'HH:mm') }}
        </span>
      </b-table-column>
      <b-table-column
        field="attendance_location_in"
        label="Location Details"
        v-slot="props"
        sortable
      >
        <span class="is-capitalize">
          {{ props.row.locationIn }}
        </span>
      </b-table-column>
      <b-table-column
        field="late_count"
        label="Late Count This Month"
        v-slot="props"
      >
        <span class="is-capitalize">
          {{ props.row.lateCount }}
          {{ props.row.lateCount > 1 ? 'times' : 'time' }}
        </span>
      </b-table-column>
    </template>
  </dashboard-list>
</template>
<script>
import { mapActions } from 'vuex'
import { formatDate, showToast } from '../../services/util'
import DashboardList from './DashboardList'
export default {
  props: ['selectedDates'],
  components: {
    DashboardList,
  },
  data() {
    return {
      page: 0,
      lastPage: 0,
      perPage: 20,
      total: 0,
      sortField: 'id',
      defaultSortOrder: 'asc',
      sortOrder: 'asc',
      items: [],
      isLoading: false,
    }
  },
  methods: {
    ...mapActions({
      actionFetchLateAttendance: 'attendance/fetchLateAttendance',
    }),
    formatDate(date, format) {
      return formatDate(date, format)
    },
    async resetState(data) {
      this.page = 0
      this.total = 0
      this.lastPage = 0
      this.items = []
      if (data) {
        this.sortField = data.sortField
        this.sortOrder = data.sortOrder
      }
      await this.fetchLateAttendance()
    },
    async fetchLateAttendance() {
      this.isLoading = true
      let params = {
        filtering_conditions: ['selectedDates'],
        selected_duration: [
          this.formatDate(this.selectedDates, 'YYYY-MM-DD'),
          this.formatDate(this.selectedDates, 'YYYY-MM-DD'),
        ],
        page: ++this.page,
        perPage: this.perPage,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      }
      try {
        let response = await this.actionFetchLateAttendance(params)
        this.items = [...this.items, ...response.data.data]
        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
        this.$emit('lateMeta', response.data.meta)
      } catch (err) {
        showToast(err.response.data.message)
        console.log(err)
      }
      this.isLoading = false
    },
    async loadMore() {
      if (this.page < this.lastPage) {
        // prevent loading too much data
        if (!this.isLoading) {
          await this.fetchLateAttendance()
        }
      }
    },
  },
  watch: {
    selectedDates: function () {
      this.resetState()
    },
  },
  mounted() {
    this.fetchLateAttendance()
  },
}
</script>
